/* ========================================================================
 * Apricot's ClickJacking Module
 * ======================================================================== */

const d = document.documentElement
const ws = window.self
const wt = window.top

const ClickJacking = () => {
  try {
    const wsHost = ws.location.hostname
    const wtHost = wt.location.hostname

    if (wsHost != wtHost) {
      console.log('This content cannot be displayed in a frame.')
      blockDisplay()

      return false
    } else {

      return true
    }
  } catch (error) {
    console.log('This content cannot be displayed in a frame.')
    console.log(error)
    blockDisplay()

    return false
  }
}

const blockDisplay = () => {
  d.style.display = 'none'
  d.style.visibility = 'hidden'
  try {
    wt.location = ws.location
  } catch (err) {
    return false
  }
}

export default ClickJacking