/* ========================================================================
 * Apricot's Modals
 * ========================================================================
 *
 * This plugin is written based on Micromodal.js
 * https://github.com/Ghosh/micromodal
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/modal.scss';
import '../scss/includes/button.scss';

// javaScript
import Utils from './CBUtils'
import CBUtils from './CBUtils';

/**
 * Modal
 *
 * @class
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Element} data.targetElem
 * @param {String} data.targetModal
 * @param {Array} data.triggers
 * @param {Function} data.onShow
 * @param {Function} data.onClose
 * @param {Boolean} data.disableScroll
 * @param {Boolean} data.videoModal
 * @param {Boolean} data.disableFocus
 * @param {Boolean} data.escClose
 * @param {Boolean} data.awaitCloseAnimation
 * @returns {{init: Function}} 
 * @returns {{show: Function}} 
 * @returns {{close: Function}} 
 * @returns {{adjustHeight: Function}} 
 */
const Modal = (() => {
  'use strict'

  class Modal {
    constructor({
      elem,
      targetElem,
      targetModal,
      triggers = [],
      onShow = () => { },
      onClose = () => { },
      openTrigger = 'data-cb-modal-trigger',
      closeTrigger = 'data-cb-modal-close',
      controlled = false,
      disableScroll = false,
      videoModal = false,
      disableFocus = false,
      escClose = true,
      awaitCloseAnimation = true
    }) {
      // Save a reference of the modal
      this.modal = document.getElementById(targetModal)
      this.shown = false

      // Exit if modal does not exist
      if (!Utils.elemExists(this.modal)) return;

      // Modal sections
      this.modalContainer = this.modal.querySelector('.cb-modal-container')
      this.modalHeader = this.modal.querySelector('.cb-modal-header')
      this.modalContent = this.modal.querySelector('.cb-modal-content')
      this.modalFooter = this.modal.querySelector('.cb-modal-footer')

      // Save a reference to the passed config

      this.config = { disableScroll, openTrigger, closeTrigger, controlled, onShow, onClose, awaitCloseAnimation, videoModal, disableFocus, escClose }

      // Register click events only if prebinding eventListeners
      if (triggers.length > 0) {
        this.registerTriggers(...triggers)
      }

      if (this.config.videoModal) {
        this.videoIframe = this.modal.querySelector('iframe')
        if (this.videoIframe) {
          this.videoSrc = CBUtils.attr(this.videoIframe, 'data-cb-src')
        }
      }

      // Resize
      this.registerResize()

      // prebind functions for event listeners
      this.onClick = this.onClick.bind(this)
      this.onKeydown = this.onKeydown.bind(this)
    }

    /**
     * Loops through all openTriggers and binds click event
     * @param  {array} triggers [Array of node elements]
     * @return {void}
     */
    registerTriggers(...triggers) {
      triggers.filter(Boolean).forEach(trigger => {
        trigger.addEventListener('click', (e) => {
          e.preventDefault()

          this.showModal()
        })
      })
    }

    registerResize() {
      const modal = this
      window.addEventListener('resize', e => {
        modal.calculateHeight()
      });
    }

    calculateHeight() {
      if (!this.modalContent) return

      this.resetHeight()

      // Only calculate when the modal is open
      if (this.config.videoModal) {
        let pageHeight = window.innerHeight
        let pageWidth = window.innerWidth
        let height = parseInt(pageHeight, 10) - 96
        let width = parseInt(pageWidth, 10) - 96
        let w = 0
        let h = 0

        if (pageWidth < pageHeight) {
          w = (width * 98) / 100;
          h = Math.round((w * 9) / 16);
        } else {
          h = (height * 98) / 100;
          w = Math.round((h * 16) / 9);
        }

        this.modalContainer.style.width = w + 'px'
        this.modalContainer.style.height = h + 'px'
      } else if (this.shown) {
        let ch = Utils.height(this.modalContainer)
        let mh = Utils.height(this.modalContent)
        let hh = 0
        let fh = 0
        let newHeight = 0

        if (this.modalHeader) hh = Utils.outerHeight(this.modalHeader)
        if (this.modalFooter) fh = Utils.outerHeight(this.modalFooter)

        newHeight = ch - (24 * 3) - hh - fh

        if (mh > newHeight) {
          this.modalContent.style.overflowY = 'auto'
          this.modalContent.style.height = newHeight + 'px'
        } else {
          this.resetHeight()
        }
      }
    }

    resetHeight() {
      if (this.config.videoModal) {
        if (!this.modalContainer) return
        this.modalContainer.style.overflowY = 'hidden'
        this.modalContainer.style.height = 'auto'
      } else {
        if (!this.modalContent) return
        this.modalContent.style.overflowY = 'hidden'
        this.modalContent.style.height = 'auto'
      }
    }

    showModal() {
      this.activeElement = document.activeElement
      this.modal.setAttribute('aria-hidden', 'false')

      if (this.config.videoModal) {
        if (this.videoIframe) {
          this.videoIframe.src = this.videoSrc
        }
      }

      this.shown = true
      this.modal.classList.add('cb-open')
      this.calculateHeight()
      this.setFocusToFirstNode()
      this.scrollBehaviour('disable')
      this.addEventListeners()
      this.config.onShow(this.modal)

      const body = document.getElementsByTagName('body')[0]
      Utils.addClass(body, 'cb-modal-open')


      // Trigger Show Event 
      const event = new CustomEvent('apricot_modalShow');
      this.activeElement.dispatchEvent(event);
    }

    closeModal(options = {}) {
      // "controlled" components just want to be notified that they should close
      if (this.config.controlled && !options.force) {
        this.config.onClose();

        return;
      }
      
      const modal = this.modal
      this.modal.setAttribute('aria-hidden', 'true')

      if (this.config.videoModal) {
        if (this.videoIframe) {
          this.videoIframe.src = ""
        }
      }

      this.shown = false
      this.removeEventListeners()
      this.scrollBehaviour('enable')
      // set focus to active elem, A11Y
      if (this.activeElement) {
        this.activeElement.focus()
      }
      this.config.onClose(this.modal)

      if (this.config.awaitCloseAnimation) {
        this.modal.addEventListener('animationend', function handler() {
          modal.classList.remove('cb-open')
          modal.removeEventListener('animationend', handler, false)
        }, false)
      } else {
        modal.classList.remove('cb-open')
      }

      const body = document.getElementsByTagName('body')[0]
      Utils.removeClass(body, 'cb-modal-open')

      // Trigger Close Event 
      const event = new CustomEvent('apricot_modalClose')
      this.activeElement.dispatchEvent(event)


      this.resetHeight()
    }

    closeModalById(targetModal) {
      this.modal = document.getElementById(targetModal)

      if (this.modal) this.closeModal({ force: true })
    }

    adjustHeightById(targetModal) {
      this.modal = document.getElementById(targetModal)

      if (this.modal) this.calculateHeight()
    }

    scrollBehaviour(toggle) {
      if (!this.config.disableScroll) return
      const body = document.querySelector('body')
      switch (toggle) {
        case 'enable':
          body.style = { ...body.style, ...{ overflow: '', height: '' } }
          break
        case 'disable':
          body.style = { ...body.style, ...{ overflow: 'hidden', height: '100vh' } }
          break
        default:
      }
    }

    addEventListeners() {
      this.modal.addEventListener('touchstart', this.onClick)
      this.modal.addEventListener('click', this.onClick)
      document.addEventListener('keydown', this.onKeydown)
    }

    removeEventListeners() {
      this.modal.removeEventListener('touchstart', this.onClick)
      this.modal.removeEventListener('click', this.onClick)
      document.removeEventListener('keydown', this.onKeydown)
    }

    onClick(event) {
      let elem = event.target
      const parent = Utils.parent(elem)

      if (parent.tagName === 'BUTTON') {
        elem = parent
      }

      if (elem.hasAttribute(this.config.closeTrigger)) {
        this.closeModal()
        event.preventDefault()
      }
    }

    onKeydown(event) {
      if (event.keyCode === 27 && this.config.escClose) this.closeModal(event)
      if (event.keyCode === 9) this.maintainFocus(event)
    }

    getFocusableNodes() {
      const nodes = this.modal.querySelectorAll(Utils.FOCUSABLE_ELEMENTS)
      return Array(...nodes)
    }

    setFocusToFirstNode() {

      if (this.config.disableFocus) return

      // A11Y, this is for UX experience
      const dialog = this.modal.querySelector('div[role="dialog"]')
      if (dialog) {
        Utils.attr(dialog, 'tabIndex', '0')
        dialog.focus()
      } else {
        const focusableNodes = this.getFocusableNodes()
        if (focusableNodes.length) focusableNodes[0].focus()
      }
    }

    maintainFocus(event) {

      const focusableNodes = this.getFocusableNodes()

      // if disableFocus is true
      if (!this.modal.contains(document.activeElement)) {
        focusableNodes[0].focus()
      } else {
        const focusedItemIndex = focusableNodes.indexOf(document.activeElement)

        if (event.shiftKey && focusedItemIndex === 0) {
          focusableNodes[focusableNodes.length - 1].focus()
          event.preventDefault()
        }

        if (!event.shiftKey && focusedItemIndex === focusableNodes.length - 1) {
          focusableNodes[0].focus()
          event.preventDefault()
        }
      }
    }
  }

  /**
   * Modal prototype ends.
   * Here on code is responsible for detecting and
   * auto binding event handlers on modal triggers
   */

  // Keep a reference to the opened modal
  let activeModal = null

  /**
   * Generates an associative array of modals and it's
   * respective triggers
   * @param  {array} triggers     An array of all triggers
   * @param  {string} triggerAttr The data-attribute which triggers the module
   * @return {array}
   */
  const generateTriggerMap = (triggers, triggerAttr) => {
    const triggerMap = []

    triggers.forEach(trigger => {
      const targetModal = trigger.attributes[triggerAttr].value
      if (triggerMap[targetModal] === undefined) triggerMap[targetModal] = []
      triggerMap[targetModal].push(trigger)
    })

    return triggerMap
  }

  /**
   * Validates whether a modal of the given id exists
   * in the DOM
   * @param  {number} id  The id of the modal
   * @return {boolean}
   */
  const validateModalPresence = id => {
    if (!document.getElementById(id)) {
      console.warn(`Apricot Modal: Seems like you have missed %c'${id}'`, 'background-color: #f8f9fa;color: #50596c;font-weight: bold;', 'ID somewhere in your code.')
      return false
    }
  }

  /**
   * Validates if there are modal triggers present
   * in the DOM
   * @param  {array} triggers An array of data-triggers
   * @return {boolean}
   */
  const validateTriggerPresence = triggers => {
    if (triggers.length <= 0) {
      console.warn(`Apricot Modal: Please specify at least one %c'micromodal-trigger'`, 'background-color: #f8f9fa;color: #50596c;font-weight: bold;', 'data attribute.')
      return false
    }
  }

  /**
   * Checks if triggers and their corresponding modals
   * are present in the DOM
   * @param  {array} triggers   Array of DOM nodes which have data-triggers
   * @param  {array} triggerMap Associative array of modals and thier triggers
   * @return {boolean}
   */
  const validateArgs = (triggers, triggerMap) => {
    validateTriggerPresence(triggers)
    if (!triggerMap) return true
    for (var id in triggerMap) validateModalPresence(id)
    return true
  }


  /**
   * Binds click handlers to all modal triggers
   * @param  {object} config [description]
   * @return void
   */
  const init = config => {
    // Create an config object with default openTrigger
    // const options = Object.assign({}, { openTrigger: 'data-cb-modal-trigger' }, config)
    const options = { ...{ openTrigger: 'data-cb-modal-trigger' }, ...config }

    let triggers = []
    let triggerMap = []

    // Check if it's a single instant or by trigger-map
    if (Utils.elemExists(options.elem)) {
      triggers.push(options.elem)
      if (options.targetElem !== undefined)
        triggerMap[options.targetElem] = triggers
    } else {
      // Collects all the nodes with the trigger
      triggers = [...document.querySelectorAll(`[${options.openTrigger}]`)]

      // Makes a mappings of modals with their trigger nodes
      triggerMap = generateTriggerMap(triggers, options.openTrigger)
    }

    // Checks if modals and triggers exist in dom
    if (validateArgs(triggers, triggerMap) === false) return false


    // For every target modal creates a new instance
    for (var key in triggerMap) {
      let value = triggerMap[key]
      options.targetModal = key
      options.triggers = [...value]

      // new Modal(options)
      activeModal = new Modal(options)
    }
  }

  /**
   * Shows a particular modal
   * @param {Object} data 
   * @param {string} data.targetModal [The id of the modal to display]
   * @param {String} data.targetModal
   * @param {Function} data.onShow
   * @param {Function} data.onClose
   * @param {Boolean} data.disableScroll
   * @param {Boolean} data.disableFocus
   * @param {Boolean} data.awaitCloseAnimation
   * @return {void}
   */

  const show = (data) => {
    const options = data || {}

    // Checks if modals and triggers exist in dom
    if (validateModalPresence(options.targetModal) === false) return false

    // clear events in case previous modal wasn't close
    if (activeModal) activeModal.removeEventListeners()

    // stores reference to active modal
    activeModal = new Modal(options)
    activeModal.showModal()
  }

  /**
 * Closes the active modal
 * @param  {string} targetModal [The id of the modal to close]
 * @return {void}
 */
  const close = targetModal => {

    targetModal ? activeModal.closeModalById(targetModal) : activeModal.closeModal({force: true})
  }


  /**
 * Adjust Height for active modal
 * @param  {string} targetModal [The id of the modal]
 * @return {void}
 */
  const adjustHeight = targetModal => {

    targetModal ? activeModal.adjustHeightById(targetModal) : activeModal.calculateHeight()
  }

  return {
    init,
    show,
    close,
    adjustHeight
  }
})()

export default Modal
