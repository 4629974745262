/* ========================================================================
 * Apricot's Form Modules
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/form.scss';
import '../scss/includes/button.scss';


// javaScript
import Utils from './CBUtils'

// ------------------------------------  SELECT

/**
 * Custom form select 
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Boolean} data.markup
 * @param {Element} data.parentElm
 * @param {Boolean} data.truncate
 * @param {Boolean} data.hiddenParent
 * @param {Boolean} data.floatingLabel
 * @returns {{change: Function}} 
 * @returns {{destroy: Function}} 
 */
const customSelectElement = (data = {}) => {
  const defaultData = {
    elem: null,
    markup: true,
    parentElm: null,
    truncate: true,
    hiddenParent: false
  }
  data = { ...defaultData, ...data };

  let elem = data.elem;
  let truncate = data.truncate;
  let hiddenParent = data.hiddenParent;
  let parentElm = data.parentElm;

  if (!Utils.elemExists(elem)) return false;

  let span1 = null
  let span3 = null
  let i = null

  const init = () => {
    if (elem.getAttribute('multiple')) return false;

    elem.customSelectElement = 'cb';

    if (data.markup) {
      span1 = document.createElement('SPAN');
      span3 = document.createElement('SPAN');

      Utils.addClass(span1, 'cb-select')
      span1.setAttribute('aria-hidden', true)

      const span2 = document.createElement('SPAN');
      span1.appendChild(span2)
      span2.appendChild(span3)

      i = document.createElement('I')
      Utils.addClass(i, ['cb-select-arrow', 'cb-glyph', 'cb-down'])

      span3.innerHTML = (elem.options[elem.selectedIndex]) ? elem.options[elem.selectedIndex].text : ''
      span3.appendChild(i)

      const div = document.createElement('DIV')
      Utils.addClass(div, 'cb-select-container')

      Utils.wrap(elem, div)
      Utils.insertAfter(elem, span1)

      Utils.addClass(elem, 'cb-replaced')
    } else {
      let p = Utils.parent(elem)

      span1 = p.querySelector('.cb-select')

      i = p.querySelector('.cb-select-arrow')
      span3 = Utils.parent(i)
      Utils.addClass(elem, 'cb-replaced')
    }

    addEvents()
  }

  const toggleEntities = (text) => {
    let k;
    const tagsList = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;'
    }

    // generate a regex object based on a list of tags, if the flag set
    // to true, it will generate a regex for the tag, otherwise it
    // will generate a list of entities
    const buildRegex = (tagFlag) => {
      var
        items = [];
      if (tagFlag) {
        for (k in tagsList) {
          items.push(k);
        }
      } else {
        for (k in tagsList) {
          items.push(tagsList[k]);
        }
      }

      return new RegExp(items.join('|'), 'g');
    }

    const checkRegex = (pattern, str) => {
      return pattern.test(str);
    }

    const replaceToEntity = (tag) => {
      return tagsList[tag] || tag;
    }

    const replaceToTag = (entity) => {
      for (var k in tagsList) {
        if (tagsList[k] == entity)
          return k;
      }
      return entity;
    }

    // are do we have html entities?
    if (checkRegex(buildRegex(false), text)) {
      // convert entities to tags
      return (text.replace(buildRegex(false), replaceToTag));
    } else {
      // no entities, convert tags to entities ...
      return (text.replace(buildRegex(true), replaceToEntity));
    }
  }

  const getRealWidth = (el) => {
    let result = 0
    let parentIsHidden = false

    if (hiddenParent) {
      if (Utils.elemExists(parentElm)) {
        parentIsHidden = (parentElm.style.display === 'none') ? true : false;
        //only if parent is hidden
        if (parentIsHidden) {
          Utils.show(parentElm);
          result = el.offsetWidth;
          Utils.hide(parentElm);
        } else {
          result = el.offsetWidth;
        }
      }
    } else {

      result = el.offsetWidth;
    }

    return result;
  }

  // check for entities in string
  const textCleanup = (value) => {
    if (!!/&(?:[a-z]+|#\d+);/.test(value)) {
      const txtArea = document.createElement('TEXTAREA')

      value = txtArea.innerHTML = value;
      value = txtArea.innerText

      Utils.remove(txtArea)
    }

    return value;
  }

  const truncateValue = (value) => {
    const containerWidth = getRealWidth(span1);
    const buttonWidth = i.offsetWidth
    let valueWidth = 0;
    let maxWidth = 0;
    let maxChars = 0;
    let tmpTxt = textCleanup(value)
    let tmp = document.createElement('SPAN')

    Utils.addClass(tmp, 'cb-tmp-element')

    document.body.appendChild(tmp)
    tmp.innerHTML = value

    valueWidth = tmp.offsetWidth;
    maxWidth = parseInt(containerWidth - buttonWidth, 10);

    if (maxWidth <= valueWidth) {
      //Calculate maximum number of characters for the string
      while (tmp.offsetWidth > maxWidth) {
        tmpTxt = textCleanup(tmp.innerHTML);
        tmpTxt = tmpTxt.substring(0, tmpTxt.length - 1);
        tmp.innerHTML = tmpTxt;
      }
      maxChars = tmpTxt.length;

      value = Utils.textTruncate(value, maxChars, 'last', '...');
    }
    Utils.remove(tmp);

    return value;
  }

  const update = e => {
    let text = (elem.options[elem.selectedIndex]) ? elem.options[elem.selectedIndex].text : ''
    let value = toggleEntities(text);
    value = (truncate) ? truncateValue(value) : value;

    i = document.createElement('I')
    Utils.addClass(i, ['cb-select-arrow', 'cb-glyph', 'cb-down'])

    span3.innerHTML = value

    span3.appendChild(i)
  }


  const disable = () => {
    Utils.addClass(span1, 'disabled');
  }

  const enable = () => {
    Utils.removeClass(span1, 'disabled');;
  }

  const addEvents = () => {
    elem.addEventListener('change', update)
    elem.addEventListener('keyup', update)

    elem.addEventListener('keydown', e => {
      if (Utils.whichKey(e) === 'ENTER') {
        e.preventDefault();
      }
    })

    //Update disabled state
    if (elem.disabled || Utils.hasClass(elem, 'disabled')) {
      disable();
    }

    // Change class names to enable styling
    elem.addEventListener('mouseenter', e => {
      Utils.addClass(span1, 'active');
    })

    elem.addEventListener('mouseleave', e => {
      Utils.removeClass(span1, 'mouseover');
    })

    elem.addEventListener('focus', e => {
      Utils.addClass(span1, 'focus');
    })

    elem.addEventListener('mouseover', e => {
      Utils.addClass(span1, 'mouseover');
    })

    elem.addEventListener('blur', e => {
      Utils.removeClass(span1, 'focus');
    })


    //adjust text length based on font-size for current viewport
    window.addEventListener('resize', update);

    //----- Define Custom events
    // can be called when we want to change the value with code
    elem.addEventListener('apricot_valueChanged', update)
    // will add custom disable style to dropDown
    elem.addEventListener('disable', disable)

    // will remove custom disable style from dropDown
    elem.addEventListener('enable', enable)

    const event = new CustomEvent('apricot_valueChanged');
    //Make sure we display the correct value, Update cb-select
    elem.dispatchEvent(event);
  }

  const change = () => {
    if ('createEvent' in document) {
      var evt = document.createEvent("HTMLEvents");
      evt.initEvent("change", false, true);
      elem.dispatchEvent(evt);
    } else {
      elem.fireEvent('onchange');
    }
  }

  const destroy = () => {
    if (elem.customSelectElement === 'cb') {
      elem.customSelectElement = null
      elem.removeEventListener('change', update)
      elem.removeEventListener('keyup', update)

      elem.removeEventListener('apricot_valueChanged', update)
      elem.removeEventListener('disable', disable)

      window.removeEventListener('resize', update)

      // Remove markup
      const parent = Utils.parent(elem);
      var span = Utils.getByClass('cb-select', parent)[0]
      const wrap = Utils.getClosest(elem, '.cb-select-container')
      Utils.remove(span)
      Utils.removeClass(elem, 'cb-replaced')
      Utils.unwrap(wrap)
    }
  }


  if (elem.customSelectElement !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
    change: change
  }
}

// ------------------------------------  CHECKBOX, RADIO BUTTON


/**
 * Custom checkbox and radio button
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Boolean} data.markup
 * @param {String} data.type
 * @returns {{destroy: Function}} 
 */
const customFormElement = (data = {}) => {
  const defaultData = {
    elem: null,
    markup: true,
    type: undefined
  }
  data = { ...defaultData, ...data };


  const typeOptions = ['checkbox', 'radio']
  let elem = data.elem
  let type = data.type

  if (!Utils.elemExists(elem)) return false;

  const init = () => {

    type = type || elem.getAttribute('type');
    if (!typeOptions.includes(type)) return false;

    // Only run once per element
    if (data.markup) {
      const $span = document.createElement('SPAN')
      Utils.addClass($span, 'cb-span')
      Utils.insertAfter(elem, $span)
    }

    if (type === 'checkbox') {
      elem.addEventListener('keydown', checkboxEvents)
    }

    elem.customFormElement = 'cb';
  }

  const checkboxEvents = e => {
    if (Utils.whichKey(e) === 'ENTER') {
      e.preventDefault();

      // Revert
      elem.checked = !elem.checked
    }
  }

  const destroy = () => {
    if (elem.customFormElement === 'cb') {
      elem.customFormElement = null
      elem.removeEventListener('keydown', checkboxEvents)

      const parent = Utils.parent(elem)
      const span = parent.querySelector('.cb-span')
      parent.removeChild(span)
    }
  }

  if (elem.customFormElement !== 'cb') {
    init();
  }

  return {
    destroy: destroy
  }
}

// ------------------------------------  FLOATING LABEL

/**
 * Floating labels for Input and Textarea tags
 *
 * @export
 * @param {Element} elem
 * @returns {{destroy: Function}} 
 */
const floatingLabel = (elem = {}) => {
  if (!Utils.elemExists(elem)) return null;

  let cbInput = null
  let label = null
  let fieldType = ''

  const init = () => {
    fieldType = elem.tagName.toLowerCase()

    if (fieldType == 'input' || fieldType == 'textarea') {
      cbInput = Utils.getClosest(elem, '.cb-input')
    } else if (fieldType == 'select') {
      cbInput = Utils.getClosest(elem, '.cb-select')
      if (Utils.browser().msie) {
        const parent = Utils.getClosest(elem, '.cb-floating-label')
        if (Utils.elemExists(parent)) {
          Utils.addClass(parent, 'cb-not-active')
        }
        return false
      }
    }


    if (Utils.elemExists(cbInput)) {
      label = Utils.getByTag('label', cbInput)[0]
    }

    if (!Utils.elemExists(label)) return null;

    if (elem.disabled === true) {
      Utils.addClass(cbInput, 'cb-disabled')

      return null;
    }

    labelChangeEvents()

    if (fieldType === 'input' || fieldType === 'textarea') {
      elem.addEventListener('keyup', labelChangeEvents)
      elem.addEventListener('change', removeLabelFocusEvents)
    } else if (fieldType === 'select') {
      elem.addEventListener('change', labelChangeEvents)
    }

    elem.addEventListener('focus', labelFocusEvents)
    elem.addEventListener('blur', removeLabelFocusEvents)


    elem.floatingLabel = 'cb';
  }

  const labelChangeEvents = e => {
    const value = getValue()

    elem.setAttribute('data-cb-value', value)

    if (value !== '') {
      Utils.addClass(label, 'cb-value-fl')
    } else {
      Utils.removeClass(label, 'cb-value-fl')
    }
  }

  const getValue = () => {
    let value = ''

    switch (fieldType) {
      case 'select':
        value = Array.from(elem.selectedOptions).map(option => option.value).toString()
        break;
      case 'input':
      case 'textarea':
        value = elem.value
        break;
    }

    return value;
  }

  const labelFocusEvents = e => {
    Utils.addClass(label, 'cb-focus-fl')

    const customEvent = new CustomEvent('apricot_inputFocus')
    elem.dispatchEvent(customEvent)
  }

  const removeLabelFocusEvents = e => {
    Utils.removeClass(label, 'cb-focus-fl')

    const customEvent = new CustomEvent('apricot_inputBlur')
    elem.dispatchEvent(customEvent)
  }

  const destroy = () => {
    if (elem.floatingLabel === 'cb') {
      elem.floatingLabel = null
      elem.removeEventListener('keyup', labelChangeEvents)
      elem.removeEventListener('focus', labelFocusEvents)
      elem.removeEventListener('blur', removeLabelFocusEvents)
      elem.removeEventListener('change', removeLabelFocusEvents)

      Utils.removeClass(label, 'cb-focus-fl')
      Utils.removeClass(label, 'cb-value-fl')
      const $f = Utils.getClosest(elem, '.cb-floating-label')

      Utils.removeClass($f, 'cb-floating-label')
    }
  }

  if (elem.floatingLabel !== 'cb') {
    init();
  }

  return {
    destroy: destroy
  }
}

// ------------------------------------  CLEAR INPUT

/**
 * Clear Input for Input tags
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Boolean} data.validate
 * @returns {{destroy: Function}} 
 */

const clearInput = (data = {}) => {
  const defaultData = {
    elem: null,
    validate: false
  }

  data = { ...defaultData, ...data }

  let elem = data.elem
  let icon = null
  let iconWrapper = null

  if (!Utils.elemExists(elem)) return null


  const cbInput = Utils.getClosest(elem, '.cb-clear-input')
  let btn = null

  if (Utils.elemExists(cbInput)) {
    btn = cbInput.querySelector('.cb-btn')
  }

  if (!Utils.elemExists(btn)) return null;

  const init = () => {
    if (elem.disabled === true) {
      Utils.addClass(cbInput, 'cb-disabled')

      return null;
    }

    if (data.validate) {
      iconWrapper = document.createElement('DIV')
      Utils.addClass(iconWrapper, 'cb-validation-label-input')
      icon = document.createElement('I')
      Utils.addClass(icon, ['cb-validation-icon', 'cb-glyph', 'cb-check'])
      Utils.attr(icon, 'aria-hidden', true)

      Utils.addClass(cbInput, ['cb-validation-success', 'cb-validation-success-ci'])

      if (cbInput.querySelector('.cb-input-icon-left') || cbInput.querySelector('.cb-input-icon-right')) {
        const container = cbInput.querySelector('.cb-input-icon-left') ?
          cbInput.querySelector('.cb-input-icon-left') : cbInput.querySelector('.cb-input-icon-right')

        Utils.insertAfter(container, icon)
      } else {
        const children = cbInput.children
        Utils.wrapAll(children, iconWrapper)
        Utils.insertAfter(iconWrapper, icon)
      }
    }

    btnChangeEvents()

    elem.addEventListener('keyup', btnChangeEvents)
    elem.addEventListener('focus', elemFocusEvents)
    elem.addEventListener('blur', removeElemFocusEvents)

    btn.addEventListener('click', clearValue)

    btn.addEventListener('focus', btnFocusEvents)
    btn.addEventListener('blur', removeBtnFocusEvents)

    elem.clearInput = 'cb';
  }

  const elemFocusEvents = e => {
    Utils.addClass(cbInput, 'cb-focus-elem-ci')
  }

  const removeElemFocusEvents = e => {
    const browser = Utils.browser().name
    const time = (browser === 'Chrome') ? 50 : 200

    setTimeout(() => {
      Utils.removeClass(cbInput, 'cb-focus-elem-ci')
    }, time)
  }

  const btnFocusEvents = e => {
    Utils.addClass(cbInput, 'cb-focus-btn-ci')
  }
  const removeBtnFocusEvents = e => {
    Utils.removeClass(cbInput, 'cb-focus-btn-ci')
  }

  const clearValue = e => {
    elem.value = ''

    btnChangeEvents()
    setTimeout(() => {
      var event1 = new Event('change')
      elem.dispatchEvent(event1)

      var event2 = new Event('keyup')
      elem.dispatchEvent(event2)

      const customEvent = new CustomEvent('apricot_clearValue')
      elem.dispatchEvent(customEvent)

      elem.focus()
    }, 10)
  }

  const btnChangeEvents = e => {
    elem.setAttribute('data-cb-value', Utils.getValue(elem))

    if (Utils.getValue(elem) !== '') {
      Utils.addClass(cbInput, 'cb-value-ci')

      const customEvent1 = new CustomEvent('apricot_hasValue')
      customEvent1.data = Utils.getValue(elem)

      elem.dispatchEvent(customEvent1)
    } else {
      Utils.removeClass(cbInput, 'cb-value-ci')

      const customEvent2 = new CustomEvent('apricot_clearValue')
      elem.dispatchEvent(customEvent2)
    }
  }

  const destroy = () => {
    if (elem.clearInput === 'cb') {
      elem.clearInput = null

      elem.removeEventListener('keyup', btnChangeEvents)
      elem.removeEventListener('focus', elemFocusEvents)
      elem.removeEventListener('blur', removeFocusEvents)

      btn.removeEventListener('click', clearValue)
      btn.removeEventListener('focus', elemFocusEvents)
      btn.removeEventListener('blur', removeFocusEvents)

      icon.remove()
      if (data.validate) {
        Utils.removeClass(cbInput, 'cb-validation-success')
      }

      Utils.removeClass(cbInput, 'cb-focus-elem-ci')
      Utils.removeClass(cbInput, 'cb-focus-btn-ci')
      Utils.removeClass(cbInput, 'cb-value-ci')
    }
  }

  if (elem.clearInput !== 'cb') {
    init();
  }

  return {
    destroy: destroy
  }
}

// ------------------------------------  SWITCH


/**
 * Custom toggle switch checkbox
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {String} data.on
 * @param {String} data.off
 * @returns {{destroy: Function}} 
 */
const toggleSwitch = (data = {}) => {
  const defaultData = {
    elem: null,
    on: 'on',
    off: 'off'
  }
  data = { ...defaultData, ...data };

  let elem = data.elem;
  let onValue = data.on;
  let offValue = data.off;

  if (!Utils.elemExists(elem)) return null;
  let label = null
  const switchToggle = Utils.getClosest(elem, '.cb-toggle-switch')
  const switchWrap = Utils.getClosest(elem, '.cb-switch')

  if (Utils.elemExists(switchToggle)) {
    label = Utils.getByTag('label', switchToggle)[0]
  }
  if (!Utils.elemExists(label)) return null;
  if (!Utils.elemExists(switchWrap)) return null;

  const init = () => {
    changeEvent()
    if (elem.disabled === true) {
      Utils.addClass(switchWrap, 'cb-disabled')
    }

    elem.addEventListener('change', changeEvent)

    elem.addEventListener('focus', focusEvent)
    elem.addEventListener('blur', removeFocusEvent)

    elem.addEventListener('mousedown', pressEvent)
    elem.addEventListener('mouseup', removePressEvent)

    elem.addEventListener('keydown', pressEvent)
    elem.addEventListener('keyup', removePressEvent)

    elem.toggleSwitch = 'cb';
  }

  const changeEvent = e => {
    if (elem.checked) {
      Utils.addClass(switchWrap, 'cb-checked')
      Utils.attr(elem, 'aria-checked', 'true')
    } else {
      Utils.removeClass(switchWrap, 'cb-checked')
      Utils.attr(elem, 'aria-checked', 'false')
    }

    changeValue(elem.checked);
  }

  const changeValue = (checked) => {
    if (checked) {
      label.innerHTML = onValue
    } else {
      label.innerHTML = offValue
    }
  }


  const pressEvent = e => {
    Utils.addClass(switchWrap, 'cb-press')
  }
  const removePressEvent = e => {
    Utils.removeClass(switchWrap, 'cb-press')
  }

  const focusEvent = e => {
    Utils.addClass(switchWrap, 'cb-focus')
  }
  const removeFocusEvent = e => {
    Utils.removeClass(switchWrap, 'cb-focus')
  }

  const destroy = () => {
    if (elem.toggleSwitch === 'cb') {
      elem.toggleSwitch = null;
      elem.removeEventListener('change', changeEvent)

      elem.removeEventListener('focus', focusEvent)
      elem.removeEventListener('blur', removeFocusEvent)

      elem.removeEventListener('mousedown', removeFocusEvent)
      elem.removeEventListener('mouseup', removeFocusEvent)

      elem.removeEventListener('keydown', removeFocusEvent)
      elem.removeEventListener('keyup', removeFocusEvent)
    }
  }

  if (elem.toggleSwitch !== 'cb') {
    init();
  }

  return {
    destroy: destroy
  }
}

// ------------------------------------  TEXT AREA
/**
 * Resizable textarea 
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Boolean} data.autoResize
 * @returns {{destroy: Function}} 
 */
const textareaResize = (data = {}) => {
  const defaultData = {
    elem: null,
    autoResize: true
  }
  data = { ...defaultData, ...data };

  let elem = data.elem;
  let autoResize = data.autoResize;

  if (!Utils.elemExists(elem)) return null;

  let elemProperty = {}

  const init = () => {
    elem.textareaResize = 'cb';

    elemProperty.rows = (!!elem.getAttribute('rows')) ? elem.getAttribute('rows') : '';
    elemProperty.height = Utils.outerHeight(elem);
    elemProperty.width = Utils.outerWidth(elem);
    elemProperty.scrollHeight = elem.scrollHeight;

    if (autoResize) {
      elem.addEventListener('keydown', elemKeydownEventAuto)
      addAutoResize();
    } else {
      addKeyboardResize();
    }

    // track textarea resize event
    elem.addEventListener('mouseup', elemMouseupEvent);
  }
  const resetTextarea = () => {
    elem.style.height = elemProperty.height + 'px';
    elem.style.width = elemProperty.width + 'px';
    elem.style.overflowY = 'auto'
    elem.setAttribute('rows', elemProperty.rows)
  }
  const addAutoResize = () => {
    elem.style.height = elemProperty.scrollHeight + 'px';

    elem.addEventListener('input', elemInputEvent)
  }

  const addKeyboardResize = () => {
    elem.addEventListener('keydown', elemKeydownEvent);
  }

  const elemKeydownEventAuto = e => {
    if (Utils.whichKey(e) === 'ESC') {
      resetTextarea();
    }
  }

  const elemKeydownEvent = e => {
    let rows = parseInt(elem.getAttribute('rows'), 10);
    elem.style.height = 'auto';

    if (e.shiftKey && e.ctrlKey && Utils.whichKey(e) === 'ENTER') {
      e.preventDefault();

      rows--;
      if (rows > elemProperty.rows) {
        elem.setAttribute('rows', rows)
      }
    } else if (e.ctrlKey && Utils.whichKey(e) === 'ENTER') {
      e.preventDefault();
      if (Utils.outerHeight(elem) < Utils.windowsDimension().height) {
        rows++
        elem.setAttribute('rows', rows++)
      }
    } else if (Utils.whichKey(e) === 'ESC') {
      resetTextarea();
    }
  }

  const elemMouseupEvent = e => {
    elem.cbX = (!isNaN(elem.cbX)) ? elem.cbX : Utils.outerWidth(elem);
    elem.cbY = (!isNaN(elem.cbY)) ? elem.cbY : Utils.outerHeight(elem);

    if (Utils.outerWidth(elem) !== elem.cbX || Utils.outerHeight(elem) !== elem.dcbY) {
      elemProperty.scrollHeight = elem.scrollHeight;
    }

    // set new height/width
    elem.cbX = Utils.outerWidth(elem);
    elem.cbY = Utils.outerHeight(elem);
  }

  const elemInputEvent = e => {
    const scrollHeight = elem.scrollHeight;

    elem.style.overflowY = 'hidden'

    if (parseInt(elemProperty.scrollHeight, 10) < parseInt(scrollHeight, 10)) {
      elem.style.height = scrollHeight + 'px';
    }
  }

  const destroy = () => {
    if (elem.textareaResize === 'cb') {
      elem.textareaResize = null
      elem.removeEventListener('keydown', elemKeydownEventAuto)
      elem.removeEventListener('keydown', elemKeydownEvent)
      elem.removeEventListener('mouseup', elemMouseupEvent)
      elem.removeEventListener('input', elemInputEvent)
      resetTextarea();
    }
  }

  if (elem.textareaResize !== 'cb') {
    init();
  }

  return {
    destroy: destroy
  }
}

// ------------------------------------  FORM VALIDATION

/**
 * Input validation state
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Boolean} data.success
 * @param {Boolean} data.error
 * @param {String} data.wrapperClass
 * @returns {{destroy: Function, changeState: Function}} 
 */
const validationState = (data = {}) => {
  const defaultData = {
    elem: null,
    success: false,
    error: false,
    aria: true,
    wrapperClass: 'cb-validation'
  }
  data = { ...defaultData, ...data };


  let elem = data.elem;
  let wrapperClass = data.wrapperClass;
  let state = (data.success) ? 'success' : ((data.error) ? 'error' : '')


  if (!Utils.elemExists(elem)) return false;

  // This is only working for Input elements
  if (elem.tagName !== 'INPUT') return false;

  const cbInput = Utils.getClosest(elem, '.cb-input')
  const parent = Utils.parent(elem)


  if (!Utils.elemExists(cbInput)) return false;

  const init = () => {
    elem.validationState = 'cb';

    buildValidationBlock()
  }

  const buildValidationBlock = () => {
    removeValidationIcon()
    Utils.addClass(cbInput, wrapperClass + '-' + state)

    addValidationIcon()
  }

  const removeValidationIcon = () => {
    Utils.removeClass(cbInput, 'cb-validation-success')
    Utils.removeClass(cbInput, 'cb-validation-error')
  }

  const addValidationIcon = () => {
    const icon = document.createElement('I')
    Utils.addClass(icon, ['cb-validation-icon', 'cb-glyph'])
    Utils.attr(icon, 'aria-hidden', true)

    if (cbInput.querySelector('.cb-input-icon-left') || cbInput.querySelector('.cb-input-icon-right')) {
      const container = cbInput.querySelector('.cb-input-icon-left') ?
        cbInput.querySelector('.cb-input-icon-left') : cbInput.querySelector('.cb-input-icon-right')

      Utils.insertAfter(container, icon)
    } else if (!parent.matches('.cb-validation-label-input')) {
      const iconWrapper = document.createElement('DIV')
      Utils.addClass(iconWrapper, 'cb-validation-label-input')

      const children = cbInput.children
      Utils.wrapAll(children, iconWrapper)
      Utils.insertAfter(iconWrapper, icon)
    } else {
      console.warn('Apricot-validationState, make sure you have correct markup in place');
      return false;
    }
    addGlyphIcon()
  }

  const addGlyphIcon = () => {
    const glyphIcon = (state === 'error' ? 'cb-exclamation' : 'cb-check')
    const icon = cbInput.querySelector('.cb-validation-icon');

    Utils.removeClass(icon, 'cb-x-mark')
    Utils.removeClass(icon, 'cb-exclamation')

    Utils.addClass(icon, glyphIcon)
  }

  const addAria = (state) => {
    if (state === 'error') {
      Utils.attr(elem, 'aria-invalid', 'true')
    } else {
      Utils.removeAttr(elem, 'aria-invalid')
    }
  }

  const changeState = (
    {
      success = false,
      error = false
    }
  ) => {

    if (elem.validationState === 'cb') {
      state = (success) ? 'success' : ((error) ? 'error' : '');
      removeValidationIcon()
      Utils.addClass(cbInput, wrapperClass + '-' + state)
      if (data.aria) {
        addAria(state)
      }
      addGlyphIcon()
    }
  }

  const destroy = () => {
    if (elem.validationState === 'cb') {
      elem.validationState = null

      removeValidationIcon()

      cbInput.querySelector('.cb-validation-icon').remove()
      Utils.removeClass(cbInput.querySelector('.cb-validation-label-input'), 'cb-validation-label-input')
      Utils.removeAttr(elem, 'aria-invalid')
    }
  }

  if (elem.validationState !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
    changeState: changeState
  }
}


// ------------------------------------  FILE UPLOAD
/**
 * Custom file upload button
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Boolean} data.markup
 * @param {String} data.label
 * @param {String} data.btnType
 * @param {String} data.btnSize
 * @param {Boolean} data.fdbk
 * @param {Boolean} data.fdbkPath
 * @param {String} data.fdbkMsg
 * @param {Boolean} data.fdbkTruncate
 * @param {String|Number} data.fdbkMaxChars
 * @param {Number} data.fdbkRemove
 * @param {String} data.fdbkPosition
 * @param {String} data.ellipseText
 * @returns {{destroy: Function}} 
 */
const fileUpload = (data = {}) => {
  const defaultData = {
    elem: null,
    markup: true,

    label: 'Choose File',
    btnType: '',
    btnSize: 'sm',

    fdbk: true,
    fdbkPath: false,
    fdbkMsg: 'No file selected...',
    fdbkTruncate: true,
    fdbkMaxChars: 'auto',
    fdbkRemove: true,
    fdbkPosition: 'bottom',
    ellipseText: '...'
  }

  data = { ...defaultData, ...data };

  let elem = data.elem;

  let fileWrapperElem = null
  let fileElem = null
  let fdbkElem = null
  let fileName = ''

  const init = () => {
    elem.fileUpload = 'cb';

    if (data.markup) {
      fileWrapperElem = document.createElement('DIV');
      Utils.addClass(fileWrapperElem, 'cb-file-upload')

      Utils.wrap(elem, fileWrapperElem)

      if (data.fdbk) {
        fdbkElem = document.createElement('SPAN')
        Utils.addClass(fdbkElem, 'cb-file-element')
        Utils.insertAfter(elem, fdbkElem)
      }

      fileElem = document.createElement('BUTTON')
      fileElem.innerHTML = data.label

      Utils.attr(fileElem, 'type', 'button')
      Utils.addClass(fileElem, ['cb-file-button', 'cb-btn', 'cb-btn-' + data.btnSize])

      if (data.btnType !== '') {
        Utils.addClass(fileElem, 'cb-btn-' + data.btnType)
      }

      Utils.insertAfter(elem, fileElem)
    } else {
      fileWrapperElem = Utils.parent(elem)
      fdbkElem = fileWrapperElem.querySelector('.cb-file-element')
      fileElem = fileWrapperElem.querySelector('button')
    }

    addEvents()
  }

  const adjustText = (value) => {
    var
      position = data.fdbkPosition ? data.fdbkPosition : 'middle',
      ellipseText = data.ellipseText ? data.ellipseText : '...';

    //don't truncate value
    if (!data.fdbk) {
      return value;
    }

    //Check text length, compare to containing span
    if (isNaN(data.fdbkMaxChars)) {

      const containerWidth = Utils.outerWidth(fileWrapperElem)
      const buttonWidth = Utils.outerWidth(fileElem)
      let valueWidth = 0
      let maxWidth = 0
      let fdbkMaxChars = 0
      let tmp = value

      const tmpElem = document.createElement('SPAN')

      Utils.addClass(tmpElem, ['cb-file-element', 'cb-tmp-element'])
      Utils.attr(tmpElem, 'id', 'tmpFileValue')
      tmpElem.innerHTML = value

      document.querySelector('body').appendChild(tmpElem)

      valueWidth = Utils.outerWidth(tmpElem)
      if (data.fdbkPosition === 'bottom') {
        maxWidth = parseInt(containerWidth, 10);
      } else {
        maxWidth = parseInt(containerWidth - buttonWidth, 10);
      }

      if (maxWidth <= valueWidth) {
        //Calculate maximum number of characters for the string
        while (Utils.outerWidth(tmpElem) > maxWidth) {
          tmp = tmpElem.innerHTML
          tmp = tmp.substring(0, tmp.length - 1)
          tmpElem.innerHTML = tmp
        }
        fdbkMaxChars = tmp.length;
        Utils.remove(tmpElem)

        value = Utils.textTruncate(value, fdbkMaxChars, position, ellipseText);
      }

    } else if (value.length > data.fdbkMaxChars) {
      value = Utils.textTruncate(value, data.fdbkMaxChars, position, ellipseText);
    }

    return value;
  }

  //ADD EVENTS
  //===============
  const addEvents = () => {
    fileElem.addEventListener('click', e => {
      e.preventDefault()

      elem.click();
    })

    elem.addEventListener('change', e => {
      //get file value
      let inputValue = elem.value
      let message = ''
      const event1 = new CustomEvent('apricot_fileSelected')
      let obj = {}
      const file = (elem.files[0]) ? elem.files[0] : null


      // Display selected file
      if (data.fdbk) {
        message = data.fdbkMsg

        if (!!inputValue) {
          message = (!data.fdbkPath) ? inputValue.split(/\\/).pop() : inputValue
          fileName = inputValue
        } 

        fdbkElem.innerHTML = adjustText(message)

        if (data.fdbkRemove) {
          const removeElem = document.createElement('A')
          Utils.addClass(removeElem, ['cb-file-element-rm', 'cb-glyph', 'cb-x-mark'])

          fdbkElem.appendChild(removeElem)

          removeElem.addEventListener('click', e => {
            e.preventDefault()

            elem.value = ''
            Utils.removeAttr(fileElem, 'title')
            Utils.removeAttr(fileElem, 'data-cb-file')

            if (data.fdbk) {
              fdbkElem.innerHTML = adjustText(data.fdbkMsg)
            } else {
              fdbkElem.innerHTML = ''
            }

            const event2 = new CustomEvent('apricot_fileRemoved')
            let obj = {}
            if (elem.fileObj) {
              obj = elem.fileObj
              elem.fileObj = null
            }
            event2.data = obj
            elem.dispatchEvent(event2)
          })
        }
      }

      if (!!inputValue) {
        Utils.attr(fileElem, 'title', inputValue)
        Utils.attr(fileElem, 'data-cb-file', inputValue)

        // trigger custom event
        obj.file = file
        obj.fullPath = inputValue
        obj.fileName = inputValue.split(/\\/).pop()
        elem.fileObj = obj
      } else {
        Utils.removeAttr(fileElem, 'data-cb-file')
        Utils.removeAttr(fileElem, 'title')
      }

      event1.data = obj
      elem.dispatchEvent(event1)
    })

    if (data.fdbk) {
      //adjust text based on font-size for current viewport
      window.addEventListener('resize', e => {
        if (fdbkElem.innerHTML !== '') {
          fdbkElem.innerHTML = adjustText(fileName)
        }
      });
    }
  }

  const destroy = () => {
    if (elem.fileUpload === 'cb') {
      elem.fileUpload = null
    }
  }

  if (elem.fileUpload !== 'cb') {
    init();
  }

  return {
    destroy: destroy
  }
}

// ------------------------------------  INPUT DROPDOWN (AUTOFILL)
/**
 * Input Dropdown 
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {String} data.event
 * @param {Boolean} data.closeOnSelect
 * @param {Boolean} data.closeOnClickOutside
 * @returns {{destroy: Function}}
 * 
 */
const inputDropdown = (data = {}) => {
  const defaultData = {
    elem: null,
    event: '',
    closeOnSelect: true,
    closeOnClickOutside: true,
  }

  data = { ...defaultData, ...data }

  let elem = data.elem
  let input = null
  let dropdown = null
  let comboBox = null

  if (!Utils.elemExists(elem)) return null

  const init = () => {
    elem.inputDropdown = 'cb'

    input = elem.querySelector('.cb-clear-input input')
    if (!Utils.elemExists(input)) return null

    dropdown = elem.querySelector('.cb-dropdown-menu')
    if (!Utils.elemExists(dropdown)) return null

    comboBox = elem.querySelector('[role="combobox"]')

    // Activate clear input
    clearInput({
      elem: input
    });

    input.addEventListener(data.event, openDropDown)
    input.addEventListener('apricot_clearValue', () => toggleDropdown(false))

    if (data.closeOnClickOutside) {
      closeOnClickOutside()
    }
  }

  const buildList = (items) => {

    const ul = dropdown.querySelector('ul')
    // clear list
    ul.innerHTML = ''

    // build new list
    Array.prototype.forEach.call(items, function (item, i) {
      const li = document.createElement('LI')
      Utils.attr(li, 'data-cb-value', item.label)
      Utils.attr(li, 'role', 'option')

      const a = document.createElement('a')
      Utils.attr(a, 'href', '#')
      a.innerHTML = item.label

      li.appendChild(a)
      ul.appendChild(li)
    })
  }

  const openDropDown = (e) => {
    let items = []
    if (e) items = (e.data) ? e.data : []

    buildList(items)

    if (items.length > 0) {
      A11yEvents()
      dropdown.querySelectorAll('li').forEach(function (item) {
        item.addEventListener('click', (e) => {
          e.preventDefault()

          const newValue = Utils.attr(item, 'data-cb-value')
          if (!Utils.isBlank(newValue)) {
            input.value = newValue
            if (data.closeOnSelect) {
              toggleDropdown(false)
            }
          }
        })
      })

      toggleDropdown(true)
    }
  }

  const toggleDropdown = (mode) => {
    // open
    if (mode) {
      Utils.addClass(elem, 'cb-open')
      Utils.attr(comboBox, 'aria-expanded', 'true')
    } else {
      Utils.removeClass(elem, 'cb-open')
      Utils.attr(comboBox, 'aria-expanded', 'false')
    }
  }

  const getFocusableNodes = () => {
    return dropdown.querySelectorAll(Utils.FOCUSABLE_ELEMENTS)
  }

  const closeOnClickOutside = () => {
    document.addEventListener('keydown', closeA11Y, true);
    document.addEventListener('click', closeA11Y, true);
  }

  const closeA11Y = (e) => {
    if (e.type === 'click') {
      if (!Utils.hasClass(elem, 'cb-open') || elem.contains(e.target)) {
        return;
      }

      toggleDropdown()
    }
  }

  const A11yEvents = () => {
    Array.prototype.forEach.call(getFocusableNodes(), (node) => {
      node.addEventListener('keydown', (e) => {
        const k = e.which || e.keyCode;

        //up/down/tab/shift
        if (!/(38|40|9|16)/.test(k)) {
          return;
        }

        e.preventDefault()
        e.stopPropagation()

        let index = 0
        const tabbingBack = e.shiftKey;
        const items = dropdown.querySelectorAll('a');

        Array.prototype.forEach.call(items, function (item, i) {
          if (node === item) {
            index = i
          }
        })

        if ((k === 9) && (((k === 9 && tabbingBack) && index === 0) || (!tabbingBack && index === items.length - 1))) { //make sure menus are closed after tab away
          toggleDropdown()
        } else { //up/down arrows
          if (k === 38 || (k === 9 && tabbingBack)) {
            index--; //up|shift+tab
          } else if (k === 40 || k === 9) {
            index++; //down|tab
          }

          if (index < 0 || index === items.length) {
            return
          }

          const newActive = items.item(index)
          newActive.focus()
        }
      })
    })
  }

  const destroy = () => {
    if (elem.inputDropdown === 'cb') {
      elem.inputDropdown = null
      if (data.closeOnClickOutside) {
        document.removeEventListener('closeA11Y', clickA11Y)
      }
    }
  }

  if (elem.inputDropdown !== 'cb') {
    init();
  }

  return {
    destroy: destroy
  }
}


// ------------------------------------  PASSWORD INPUT
/**
 * Show/Hide Password value
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @returns {{destroy: Function}} 
 */

const passwordInput = (data = {}) => {
  const defaultData = {
    elem: null
  }

  data = { ...defaultData, ...data }

  const elem = data.elem
  if (!Utils.elemExists(elem)) return null

  let btn = null

  const init = () => {
    elem.passwordInput = 'cb'

    btn = Utils.getClosest(elem, '.cb-input').querySelector('.cb-btn')
    if (!Utils.elemExists(btn)) return null;

    if (elem.disabled === true) {
      Utils.addClass(elem, 'cb-disabled')
      Utils.addClass(btn, 'cb-disabled')

      return null;
    }
    const idElem = (Utils.attr(elem, 'id')) ? Utils.attr(elem, 'id') : Utils.uniqueID(5, 'apricot_')
    Utils.attr(elem, 'id', idElem)

    Utils.attr(btn, 'aria-controls', idElem)
    Utils.attr(btn, 'aria-pressed', 'false')

    btn.addEventListener('click', btnEvents)
  }

  const btnEvents = (e) => {

    e.preventDefault()
    e.stopPropagation()

    const type = Utils.attr(elem, 'type')
    const icon = btn.querySelector('.cb-glyph')
    const sr = btn.querySelector('.sr-only')

    if (type === 'text') {
      Utils.attr(elem, 'type', 'password')
      Utils.addClass(icon, 'cb-see-off')
      Utils.removeClass(icon, 'cb-see-on')
      Utils.attr(btn, 'aria-pressed', 'false')
    } else {
      Utils.attr(elem, 'type', 'text')
      Utils.removeClass(icon, 'cb-see-off')
      Utils.addClass(icon, 'cb-see-on')
      Utils.attr(btn, 'aria-pressed', 'true')
    }
  }


  const destroy = () => {
    if (elem.passwordInput === 'cb') {
      elem.passwordInput = null

      Utils.attr(elem, 'type', 'password')
      btn.removeEventListener('click', btnEvents)
    }
  }

  if (elem.passwordInput !== 'cb') {
    init();
  }

  return {
    destroy: destroy
  }
}


const CBForm = {
  customSelectElement,
  customFormElement,
  floatingLabel,
  toggleSwitch,
  textareaResize,
  validationState,
  fileUpload,
  clearInput,
  inputDropdown,
  passwordInput
}

window.cb = window.cb || {};
window.cb.apricot = window.cb.apricot || {};
window.cb.apricot.CBForm = CBForm;

export default CBForm;
