/* ========================================================================
 * Apricot's Input Mask Module
 * ======================================================================== */

// SCSS
import '../scss/includes/input-mask.scss';

// javaScript
import Utils from './CBUtils'

// ------------------------------------  INPUT MASK
/**
 * Input Mask
 * 
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {String} data.cbPlaceholder
 * @param {String} data.cbMask
 * @param {String} data.cbChar
 * @param {String} data.cbNum
 * @returns {{destroy: Function}}
 * 
 */
const InputMask = (data = {}) => {
  const defaultData = {
    elem: null,
    cbPlaceholder: null,
    cbMask: null,
    cbChar: '_',
    cbNum: '#xXdDmMyY9'
  }

  const elem = data.elem
  if (!Utils.elemExists(elem)) return false;

  const tmp = elem.dataset
  data = { ...defaultData, ...data }
  data = { ...data, ...tmp }

  let cbMask = data.cbMask
  let cbPlaceholder = data.cbPlaceholder

  let char = data.cbChar
  let num = data.cbNum


  const init = () => {
    elem.inputMask = 'cb'

    Utils.attr(elem, 'placeholder', cbPlaceholder)
    Utils.attr(elem, 'maxlength', cbPlaceholder.length)

    elem.addEventListener('keyup', handleValueChange)
  }

  const handleValueChange = (e) => {
    const value = e.target.value

    if (value.length > 0) {
      elem.value = setValue(e)
    }
  }

  const setValue = (e) => {
    const placeholder = cbMask || cbPlaceholder
    const value = e.target.value
    let newValue = ''
    let strippedValue = ''
    let isInt = false
    let isLetter = false
    let matchesNumber = false
    let matchesLetter = false

    // strip special characters
    strippedValue = cbMask ? value.replace(/\W/g, "") : value.replace(/\D/g, "");
    // strippedValue = strippedValue.replace(/\./g, "")

    for (let i = 0, j = 0; i <= placeholder.length; i++) {
      isInt = !isNaN(parseInt(strippedValue[j]));
      isLetter = strippedValue[j] ? strippedValue[j].match(/[A-Z]/i) : false;
      matchesNumber = num.indexOf(placeholder[i]) >= 0;
      matchesLetter = char.indexOf(placeholder[i]) >= 0;

      if ((matchesNumber && isInt) || (cbMask && matchesLetter && isLetter)) {
        newValue += strippedValue[j++];
      } else if ((!cbMask && !isInt && matchesNumber) || (cbMask && ((matchesLetter && !isLetter) || (matchesNumber && !isInt)))) {

        return newValue;
      } else {
        newValue += placeholder[i];
      }
      if (strippedValue[j] == undefined) {
        break;
      }
    }

    return newValue
  }

  const destroy = () => {
    if (elem.inputMask === 'cb') {
      elem.inputMask = null
    }

    Utils.removeAttr(elem, 'maxlength')
    elem.removeEventListener('keyup', handleValueChange)
  }

  if (elem.inputMask !== 'cb') {
    init()
  }

  return {
    destroy: destroy
  }
}

export default InputMask
