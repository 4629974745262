/* ========================================================================
 * Apricot's Form Validation Module
 * ======================================================================== */

import CBRegExpValidation from './CBRegExpValidation'
import Utils from './CBUtils'

// ------------------------------------  FIELD VALIDATION
/**
 * FIELD Validation 
 * Checkbox, Radio button, Input, Textarea and Select
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {String} data.cbValidate
 * @param {String} data.cbRegex
 * @param {String} data.cbErrMsg
 * @param {String} data.cbValidMsg
 * @param {Boolean} data.cbRequired
 * @param {Boolean} data.cbSubmit
 * @param {{regEx: String, msg: String}[]} data.rules
 * @returns {{destroy: Function}}
 * 
 */
const fieldValidation = (data = {}) => {
  const defaultData = {
    elem: null,

    cbValidate: '',
    cbRegex: '',
    cbErrMsg: '',
    cbValidMsg: '',
    cbRequired: false,
    cbSubmit: false,
    rules: []
  }

  const elem = data.elem

  if (!Utils.elemExists(elem)) return false;

  const tmp = elem.dataset
  data = {
    ...defaultData,
    ...data
  }
  data = {
    ...data,
    ...tmp
  }

  let cbRegex = data.cbRegex
  const validationType = data.cbValidate
  const errMessage = data.cbErrMsg
  const validMessage = data.cbValidMsg

  const required = Utils.isTrue(data.cbRequired)
  const submit = Utils.isTrue(data.cbSubmit)

  let fieldType = ''

  const init = () => {
    elem.fieldValidation = 'cb'

    fieldType = elem.tagName.toLowerCase()

    if (fieldType === 'input' &&
      (Utils.attr(elem, 'type') === 'checkbox' || Utils.attr(elem, 'type') === 'radio')) {
      fieldType = Utils.attr(elem, 'type')
    }

    // Mark submit state, 
    if (submit) {
      Utils.attr(elem, 'data-cb-submit', true)
    } else {
      Utils.removeAttr(elem, 'data-cb-submit')
    }

    try {
      validate()
    } catch (err) {
      console.log(err)
    }
  }

  const validate = () => {
    // Set validation
    let validation = {
      valid: false,
      required: false,
      errMsg: '',
      validMsg: ''
    }

    // If not required, pass true
    if (required) {
      validation.required = requiredField()
    } else {
      validation.required = true
    }

    // If validation is not required, pass true
    validation.valid = validateField()


    // Error Message
    validation.errMsg = (!Utils.isBlank(errMessage)) ? errMessage : errorMessage()

    // Validation Message
    validation.validMsg = (!Utils.isBlank(validMessage)) ? validMessage : ''
    elem.validation = validation

    const event = new CustomEvent('apricot_validated')
    event.data = validation

    elem.dispatchEvent(event)
  }

  const getFieldValue = () => {
    let value = ''
    const name = (Utils.attr(elem, 'name')) ? Utils.attr(elem, 'name') : ''

    switch (fieldType) {
      case 'checkbox':
        if (document.querySelectorAll(`[name="${name}"]`).length > 0) {
          value = Array.from(document.querySelectorAll(`[name="${name}"]:checked`)).map(r => r.value).toString();
        } else {
          value = (elem.checked) ? elem.value : ''
        }
        break;
      case 'radio':
        value = Array.from(document.querySelectorAll(`[name="${name}"]:checked`)).map(r => r.value).toString();
        break;
      case 'select':
        let selectedOptions = elem.selectedOptions || [].filter.call(elem.options, option => option.selected);
        value = [].map.call(selectedOptions, option => option.value).toString()
        break;
      case 'input':
      case 'textarea':
        value = elem.value
        break;
    }

    return value
  }

  const requiredField = () => {
    return !Utils.isBlank(getFieldValue())
  }

  const validateField = () => {
    const value = getFieldValue()

    let valid = false
    let rules = data.rules

    // Single validation rule
    if (validationType) {
      switch (validationType) {
        case 'password':
          valid = CBRegExpValidation.password(value)
          break;
        case 'email':
          valid = CBRegExpValidation.email(value)
          break;
        case 'address':
          valid = CBRegExpValidation.address(value)
          break;
        case 'zipCode':
          valid = CBRegExpValidation.zipCode(value)
          break;
        case 'phone':
          valid = CBRegExpValidation.phone(value)
          break;
        case 'number':
          valid = CBRegExpValidation.validateNumber(value)
          break;
        case 'alphabetic':
          valid = CBRegExpValidation.validateAlphabetic(value)
          break;
        case 'alphanumeric':
          valid = CBRegExpValidation.validateAlphanumeric(value)
          break;
        case 'date':
          valid = CBRegExpValidation.date(value)
          break;
        case 'year':
          valid = CBRegExpValidation.year(value)
          break;
        case 'month':
          valid = CBRegExpValidation.month(value)
          break;
        case 'day':
          valid = CBRegExpValidation.day(value)
          break;
        default:
          if (CBRegExpValidation.RULES[validType]) {
            valid = value.match(CBRegExpValidation.RULES[validType])
          }
          break;
      }
    } else if (cbRegex) {
      if (typeof cbRegex == 'object') {} else {
        // remove / from around the string
        cbRegex = cbRegex.slice(1, -1)
      }

      valid = new RegExp(cbRegex).test(value)

    } else if (rules && rules.length > 0) {

      rules.forEach((obj) => {
        if (obj.regEx) {
          const validRegex = obj.regEx
          obj.valid = new RegExp(validRegex).test(value)
        } else if (obj.callback) {
          obj.valid = obj.callback()
        }
      })

      valid = rules
    } else {
      valid = true
    }

    return valid
  }

  const errorMessage = () => {
    let msg = ''

    // Single validation rule
    switch (validationType) {
      case 'password':
        msg = CBRegExpValidation.MESSAGES.password
        break;
      case 'email':
        msg = CBRegExpValidation.MESSAGES.email
        break;
      case 'address':
        msg = CBRegExpValidation.MESSAGES.address
        break;
      case 'zipCode':
        msg = CBRegExpValidation.MESSAGES.zipCode
        break;
      case 'phone':
        msg = CBRegExpValidation.MESSAGES.phone
        break;
      case 'number':
        msg = CBRegExpValidation.MESSAGES.number
        break;
      case 'alphabetic':
        msg = CBRegExpValidation.MESSAGES.alphabetic
        break;
      case 'alphanumeric':
        msg = CBRegExpValidation.MESSAGES.alphanumeric
        break;
      case 'date':
        msg = CBRegExpValidation.MESSAGES.date
        break;
      case 'year':
        msg = CBRegExpValidation.MESSAGES.year
        break;
      case 'month':
        msg = CBRegExpValidation.MESSAGES.month
        break;
      case 'day':
        msg = CBRegExpValidation.MESSAGES.day
        break;
    }

    return msg
  }

  const destroy = () => {
    elem.validation = null
    elem.removeEventListener('apricot_validated')
  }

  init()

  return {
    destroy: destroy
  }
}

// ------------------------------------ ERROR HANDLING 
/**
 * Error Handling 
 * Single error message per form field
 * Checkbox, Radio button, Input, Textarea and Select
 * Based on the assumption that all required markup is in place
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Element} data.helper
 * @param {Boolean} data.errBlock
 * @param {Object} data.validationObj
 * @param {Boolean} data.validationObj.valid
 * @param {Boolean} data.validationObj.required
 * @param {String} data.validationObj.errMsg
 * @param {validMsg} data.validationObj.validMsg
 * @returns {{destroy: Function}} 
 * 
 */
const errorHandling = (data = {}) => {
  const defaultData = {
    elem: null,
    helper: null,
    errBlock: false,
    validationObj: {}
  }
  const elem = data.elem
  const helper = data.helper

  if (!Utils.elemExists(elem)) return false;

  data = {
    ...defaultData,
    ...data
  }

  let errBlock = Utils.isTrue(data.errBlock)
  const hasHelper = Utils.elemExists(helper)

  let idHelper = ''
  let parent = null
  let icon = null
  let helperItem = null
  let fieldType = ''
  let validationObj = data.validationObj

  let showValidMsg = true

  const init = () => {
    elem.errorHandling = 'cb'

    if (hasHelper) {
      idHelper = (Utils.attr(helper, 'id')) ? Utils.attr(helper, 'id') : Utils.uniqueID(5, 'apricot_')
      Utils.attr(helper, 'id', idHelper)

      if (errBlock) {
        if (helper.tagName === 'A') {
          Utils.attr(helper, 'href', `#${idHelper}`)
          helperItem = Utils.getClosest(helper, 'li')
          addFocusEvent()
        }
      }
    } else {
      errBlock = false
      idHelper = ''
    }

    fieldType = elem.tagName.toLowerCase()

    if (fieldType === 'input' &&
      (Utils.attr(elem, 'type') === 'checkbox' || Utils.attr(elem, 'type') === 'radio')) {
      fieldType = Utils.attr(elem, 'type')
    }

    // keep the original value 
    Utils.attr(elem, 'data-cb-describedby', Utils.attr(elem, 'aria-describedby'))

    getTags()

    if (!Utils.isEmptyObject(validationObj)) {
      checkValidation(validationObj)
    }
    elem.addEventListener('apricot_validated', (e) => {

      checkValidation(e.data)
    })
  }

  const addFocusEvent = () => {
    helper.addEventListener('click', (e) => {
      e.preventDefault()

      elem.focus()
    })
  }

  const checkValidation = (validation) => {
    if (!validation) return

    if (!validation.validMsg || Utils.isBlank(validation.validMsg)) {
      showValidMsg = false
    }

    if (validation.required && validation.valid) {
      validationState(true)

      if (showValidMsg) {
        errorMessage(validation.validMsg)
      } else {
        errorMessage('')
      }
    } else {
      validationState(false)
      errorMessage(validation.errMsg)
    }

    if (errBlock) {
      showHideDialog()
    }
  }

  const showHideDialog = () => {
    const helperList = Utils.getClosest(helper, 'ul')
    let dialog = Utils.getClosest(helper, '.cb-dialog')
    let show = false

    helperList.querySelectorAll('li').forEach((item) => {
      if (!Utils.hasClass(item, 'cb-hidden')) {
        show = true
      }
    })

    if (show) {
      Utils.removeClass(dialog, 'cb-hidden')
    } else {
      Utils.addClass(dialog, 'cb-hidden')
    }
  }

  // true: valid
  // false: invalid
  const validationState = (mode) => {
    if (mode) {
      Utils.removeClass(parent, 'cb-validation-state')
      Utils.removeClass(parent, 'cb-validation-error')
      Utils.addClass(parent, 'cb-validation-success')

      Utils.removeClass(icon, 'cb-exclamation')
      Utils.addClass(icon, ['cb-glyph', 'cb-check'])

      Utils.removeClass(helper, 'cb-validation-error')
      Utils.addClass(helper, 'cb-validation-success')

      if (hasHelper && !showValidMsg) {
        Utils.addClass(helper, 'cb-hidden')
        if (errBlock) {
          Utils.addClass(helperItem, 'cb-hidden')
        }
      }
      if (fieldType === 'radio') {
        updateAriaDescribedBy(0, parent)
        Utils.removeAttr(parent, 'aria-invalid')
      } else if (fieldType === 'checkbox') {
        const legendCheck1 = parent.querySelector('legend')
        updateAriaDescribedBy(0, parent)
        if (legendCheck1.querySelector('.sr-only')) {
          legendCheck1.querySelector('.sr-only').innerHTML = 'required'
        }
      } else {
        updateAriaDescribedBy(0, elem)
        Utils.removeAttr(elem, 'aria-invalid')
      }
    } else {
      Utils.removeClass(parent, 'cb-validation-state')
      Utils.removeClass(parent, 'cb-validation-success')
      Utils.addClass(parent, 'cb-validation-error')

      Utils.removeClass(icon, 'cb-check')
      Utils.addClass(icon, ['cb-glyph', 'cb-exclamation'])

      if (hasHelper) {
        Utils.addClass(helper, 'cb-validation-error')
        Utils.removeClass(helper, 'cb-validation-success')
      }

      if (fieldType === 'radio') {
        updateAriaDescribedBy(1, parent, idHelper)
        Utils.attr(parent, 'aria-invalid', true)
      } else if (fieldType === 'checkbox') {
        const legendCheck2 = parent.querySelector('legend')
        updateAriaDescribedBy(1, parent, idHelper)

        if (legendCheck2.querySelector('.sr-only')) {
          legendCheck2.querySelector('.sr-only').innerHTML = 'required - invalid'
        }
      } else {
        updateAriaDescribedBy(1, elem, idHelper)
        Utils.attr(elem, 'aria-invalid', true)
      }
    }
  }

  // 0: remove
  // 1: add
  const updateAriaDescribedBy = (mode, node, idHelper) => {
    let ariaDescribedby = Utils.attr(node, 'data-cb-describedby') || null;
    if (mode) {
      if (ariaDescribedby) {
        Utils.attr(node, 'aria-describedby', `${idHelper} ${ariaDescribedby}`)
      } else {
        Utils.attr(node, 'aria-describedby', idHelper)
      }
    } else {
      Utils.removeAttr(node, 'aria-describedby')
      if (ariaDescribedby) {
        Utils.attr(node, 'aria-describedby', ariaDescribedby)
      }
    }
  }

  const errorMessage = (msg) => {
    if (!hasHelper) return

    if (!Utils.isBlank(msg)) {
      Utils.removeClass(helper, 'cb-hidden')
      if (errBlock) {
        Utils.removeClass(helperItem, 'cb-hidden')
      }

      helper.innerHTML = msg
    } else {
      Utils.addClass(helper, 'cb-hidden')
      if (errBlock) {
        Utils.addClass(helperItem, 'cb-hidden')
      }
      helper.innerHTML = ''
    }
  }

  const getTags = () => {
    switch (fieldType) {
      case 'checkbox':
      case 'radio':
        parent = Utils.getClosest(elem, 'fieldset')
        break;

      case 'select':
        parent = Utils.getClosest(elem, '.cb-select')
        break;

      case 'input':
      case 'textarea':
        parent = Utils.getClosest(elem, '.cb-input')
        icon = parent.querySelector('.cb-validation-icon')
        break;
    }
    // we keep the original value 
    parent && Utils.attr(parent, 'data-cb-describedby', Utils.attr(parent, 'aria-describedby'))
  }

  const destroy = () => {
    if (elem.errorHandling === 'cb') {
      elem.errorHandling = null
    }
  }

  if (elem.errorHandling !== 'cb') {
    init();
  }

  return {
    destroy: destroy
  }
}

// ------------------------------------  BLOCK ERROR HANDLING 
/**
 * Error Block Handling 
 * Multiple error messages per form field
 * Checkbox, Radio button, Input, Textarea and Select
 *
 * @export
 * @param {Object} data 
 * @param {Element} data.elem
 * @param {Element} data.helper
 * @param {Boolean} data.markup
 * @param {{regEx: String, callback: Function, msg: String, valid: Boolean}[]} data.rules
 * @returns {{validateAndReset: Function}} 
 * @returns {{resetValidation: Function}} 
 * @returns {{destroy: Function}} 
 * 
 */
const errorBlockHandling = (data = {}) => {
  const defaultData = {
    elem: null,
    helper: null,
    markup: true,
    rules: []
  }

  const elem = data.elem
  const helper = data.helper

  if (!Utils.elemExists(elem)) return false;

  data = {
    ...defaultData,
    ...data
  }

  let idHelper = ''
  let parent = null
  let groupLegend = null
  let icon = null
  let inputBtn = null
  let validationStatus = null

  const markup = Utils.isTrue(data.markup)
  const hasHelper = Utils.elemExists(helper)

  let rules = data.rules
  let fieldType = ''

  const init = () => {
    if (rules.length <= 0) return
    elem.errorBlockHandling = 'cb'

    fieldType = elem.tagName.toLowerCase()

    if (fieldType === 'input' &&
      (Utils.attr(elem, 'type') === 'checkbox' || Utils.attr(elem, 'type') === 'radio')) {
      fieldType = Utils.attr(elem, 'type')
    }

    // keep the original value 
    Utils.attr(elem, 'data-cb-describedby', Utils.attr(elem, 'aria-describedby'))

    // get parent tag
    getTags()

    if (hasHelper) {
      idHelper = (Utils.attr(helper, 'id')) ? Utils.attr(helper, 'id') : Utils.uniqueID(5, 'apricot_')
      Utils.attr(helper, 'id', idHelper)

      // for radio, validation happens on fieldset level
      if (fieldType === 'radio') {
        updateAriaDescribedBy(parent, idHelper)
      } else if (fieldType === 'checkbox') {
        updateAriaDescribedBy(groupLegend, idHelper)
      } else {
        updateAriaDescribedBy(elem, idHelper)
      }

      if (markup) {

        buildErrorBlock()
      }
    }

    // ---------------- Add Events
    addEvents()

    if (Utils.attr(elem, 'data-cb-submit') && hasHelper) {
      formWasSubmitted()
    }
  }

  // 0: remove
  // 1: add
  const updateAriaDescribedBy = (node, idHelper) => {
    let ariaDescribedby = Utils.attr(node, 'data-cb-describedby') || null;
    if (ariaDescribedby) {
      Utils.attr(node, 'aria-describedby', `${idHelper} ${ariaDescribedby}`)
    } else {
      Utils.attr(node, 'aria-describedby', idHelper)
    }
  }

  const addEvents = () => {
    if (fieldType === 'input') {
      if (Utils.elemExists(elem.nextSibling)) {
        if (elem.nextSibling.tagName.toLowerCase() === 'button' &&
          Utils.attr(elem.nextSibling, 'aria-controls') === Utils.attr(elem, 'id')) {
          inputBtn = elem.nextSibling
        }
      }
    }

    // Listen to validation event to start
    elem.addEventListener('apricot_validated', (e) => {
      if (e.data) {

        rules = {}
        rules = e.data.valid

        checkValidation()

        if (Utils.attr(elem, 'data-cb-submit')) {
          showColoredState()
          formWasSubmitted()
        } else {
          formWasSubmitted()
        }
      }
    })

    if (fieldType === 'radio' || fieldType === 'checkbox') {
      const name = (Utils.attr(elem, 'name')) ? Utils.attr(elem, 'name') : ''
      document.querySelectorAll(`[name="${name}"]`).forEach((node) => {
        node.addEventListener('keyup', radioCheckFieldValidation)
        node.addEventListener('change', radioCheckFieldValidation)
      })
    } else {
      elem.addEventListener('keyup', callFieldValidation)
      elem.addEventListener('change', callFieldValidation)
    }

    if (helper) {
      if (fieldType === 'radio' || fieldType === 'checkbox') {
        const name = (Utils.attr(elem, 'name')) ? Utils.attr(elem, 'name') : ''
        document.querySelectorAll(`[name="${name}"]`).forEach((node) => {
          node.addEventListener('focus', showErrorBlock)
          node.addEventListener('blur', () => {
            hideErrorBlock()
          })
        })
      } else {
        elem.addEventListener('focus', showErrorBlock)
        elem.addEventListener('blur', () => {
          hideErrorBlock()
        })
      }

      if (inputBtn) {
        inputBtn.addEventListener('focus', showErrorBlock)
        inputBtn.addEventListener('blur', hideErrorBlock)
      }
    }
  }

  const formWasSubmitted = () => {
    showErrorBlock()
    hideErrorBlock()
  }

  // validation per radio, checkbox group
  const radioCheckFieldValidation = (e) => {

    callFieldValidation(e)
  }

  const callFieldValidation = (e) => {
    const k = e.which || e.keyCode;
    const tabbingBack = e.shiftKey;

    if (fieldType === 'input' || fieldType === 'textarea') {
      //tab or shift + tab
      if (isNaN(k) || k === 9 || k === 16 || (k === 9 && tabbingBack)) return
    } else if (fieldType === 'radio' || fieldType === 'checkbox' || fieldType === 'select') {
      if (k === 9 || k === 16 || (k === 9 && tabbingBack)) return
    }

    fieldValidation({
      elem: elem,
      rules: rules
    })
  }

  const showErrorBlock = () => {
    if (Utils.elemExists(helper)) {
      Utils.removeClass(helper, 'cb-hidden')
      Utils.addClass(helper, 'cb-show')
    }
  }

  const hideErrorBlock = () => {
    if (typeof validationStatus === 'boolean') {
      if (Utils.elemExists(helper)) {
        if (validationStatus) {
          Utils.removeClass(helper, 'cb-show-err-state')
          helper.style.height = ''

          Utils.addClass(helper, 'cb-hidden')
          Utils.removeClass(helper, 'cb-show')
        } else {
          Utils.addClass(helper, 'cb-show-err-state')
        }
      }

      // Check validation of whole group
      validationState()
    }
  }

  const showColoredState = () => {
    if (typeof validationStatus === 'boolean') {
      if (validationStatus) {
        Utils.removeClass(helper, 'cb-show-err-state')
      } else {
        Utils.addClass(helper, 'cb-show-err-state')
      }

      // Check validation of whole group
      validationState()
    }
  }

  const checkValidation = () => {
    validationStatus = blockValidationState()

    if (helper) {
      // Check validation of each item
      itemValidationState()
    } else {
      validationState()
    }
  }

  // true: valid
  // false: invalid
  const validationState = () => {
    if (validationStatus) {
      Utils.removeClass(parent, 'cb-validation-state')
      Utils.removeClass(parent, 'cb-validation-error')
      Utils.addClass(parent, 'cb-validation-success')

      Utils.removeClass(icon, 'cb-exclamation')
      Utils.addClass(icon, ['cb-glyph', 'cb-check'])

      if (fieldType === 'radio') {
        Utils.removeAttr(parent, 'aria-invalid')
      } else if (fieldType === 'checkbox') {
        Utils.removeAttr(groupLegend, 'aria-invalid')
      } else {
        Utils.removeAttr(elem, 'aria-invalid')
      }
    } else {
      Utils.removeClass(parent, 'cb-validation-state')
      Utils.removeClass(parent, 'cb-validation-success')
      Utils.addClass(parent, 'cb-validation-error')

      Utils.removeClass(icon, 'cb-check')
      Utils.addClass(icon, ['cb-glyph', 'cb-exclamation'])

      if (fieldType === 'radio') {
        Utils.attr(parent, 'aria-invalid', true)
      } else if (fieldType === 'checkbox') {
        Utils.attr(groupLegend, 'aria-invalid', true)
      } else {
        Utils.attr(elem, 'aria-invalid', true)
      }
    }
  }

  const blockValidationState = () => {
    let valid = true

    rules.forEach((rule) => {
      if (!rule.valid) valid = false
    })

    return valid
  }

  const itemValidationState = () => {
    const items = helper.querySelectorAll('.cb-input-helper')

    if (items.length == 0) return

    rules.forEach((rule, index) => {
      if (rule.msg && !Utils.isBlank(rule.msg)) {
        if (rule.valid) {
          items[index].querySelector('span').innerHTML = 'valid, '
          Utils.removeClass(items[index], 'cb-validation-error')
          Utils.addClass(items[index], 'cb-validation-success')
        } else {
          items[index].querySelector('span').innerHTML = 'attention, '
          Utils.removeClass(items[index], 'cb-validation-success')
          Utils.addClass(items[index], 'cb-validation-error')
        }
      }
    })

    // Change main state
    validationStatus = blockValidationState()
    if (Utils.hasClass(parent, 'cb-validation-success') || Utils.hasClass(parent, 'cb-validation-error')) {
      validationState()
    }
  }

  const buildErrorBlock = () => {
    let errMsgCount = 0
    Utils.addClass(helper, 'cb-hidden')
    const ul = document.createElement('UL')
    rules.forEach((rule) => {
      const li = document.createElement('LI')
      Utils.addClass(li, 'cb-input-helper')
      const span = document.createElement('SPAN')
      Utils.addClass(span, 'sr-only')
      span.innerHTML = 'attention, '
      const p = document.createElement('P')

      if (rule.hasOwnProperty('valid') && !rule.valid) {
        Utils.addClass(li, 'cb-validation-error')
      }

      if (rule.msg && !Utils.isBlank(rule.msg)) {
        p.innerHTML = rule.msg
        li.appendChild(span)
        li.appendChild(p)
        errMsgCount++
      } else {
        p.innerHTML = ''
        Utils.addClass(li, 'cb-hidden')
      }

      ul.appendChild(li)
    })

    helper.appendChild(ul)
  }

  const getTags = () => {
    switch (fieldType) {
      case 'checkbox':
        parent = Utils.getClosest(elem, 'fieldset') ? Utils.getClosest(elem, 'fieldset') :
          Utils.getClosest(elem, '.cb-checkbox')

        if (parent.tagName.toLowerCase() === 'fieldset') {
          groupLegend = parent.querySelector('legend') ? parent.querySelector('legend') : parent
        } else {
          groupLegend = elem
        }
        break;
      case 'radio':
        parent = Utils.getClosest(elem, 'fieldset')
        break;

      case 'select':
        parent = Utils.getClosest(elem, '.cb-select')
        icon = parent.querySelector('.cb-validation-icon')
        break;

      case 'input':
      case 'textarea':
        parent = Utils.getClosest(elem, '.cb-input')
        icon = parent.querySelector('.cb-validation-icon')

        break;
    }

     // keep the original value 
     parent && Utils.attr(parent, 'data-cb-describedby', Utils.attr(parent, 'aria-describedby'))
     groupLegend && Utils.attr(groupLegend, 'data-cb-describedby', Utils.attr(groupLegend, 'aria-describedby')) 
  }

  const resetInputState = () => {
    Utils.addClass(parent, 'cb-validation-state')

    Utils.removeClass(parent, 'cb-validation-error')
    Utils.removeClass(parent, 'cb-validation-success')

    Utils.removeClass(icon, 'cb-exclamation')
    Utils.removeClass(icon, 'cb-check')
    Utils.removeClass(icon, 'cb-glyph')
    Utils.addClass(icon, 'cb-validation-icon')


    if (fieldType === 'radio') {
      Utils.removeAttr(parent, 'aria-invalid')
    } else if (fieldType === 'checkbox') {
      Utils.removeAttr(groupLegend, 'aria-invalid')
    } else {
      Utils.removeAttr(elem, 'aria-invalid')
    }
    Utils.removeAttr(elem, 'data-cb-submit')
    validationStatus = null
  }

  // -------------- Public
  const validateAndReset = (newRules) => {
    if (newRules && !Utils.isEmptyObject(newRules)) {
      rules = {}
      rules = newRules.valid
    }

    // run through the whole process
    checkValidation()
    formWasSubmitted()

    // make sure no more validation is fired 
    elem.removeEventListener('apricot_validated', {})
    elem.addEventListener('focus', resetOnFocus)
  }

  const resetOnFocus = () => {
    destroy()
    resetValidation()

    elem.removeEventListener('focus', resetOnFocus)

    const event = new CustomEvent('apricot_errBlock_focus')
    elem.dispatchEvent(event)
  }

  const resetValidation = () => {
    resetInputState()

    if (helper) {
      const items = helper.querySelectorAll('.cb-input-helper')

      if (items.length == 0) return
      rules.forEach((rule, index) => {
        if (rule.msg && !Utils.isBlank(rule.msg)) {
          items[index].querySelector('span').innerHTML = ''
          Utils.removeClass(items[index], 'cb-validation-error')
          Utils.removeClass(items[index], 'cb-validation-success')
        }
      })
    }
  }

  const destroy = () => {
    if (elem.errorBlockHandling === 'cb') {
      elem.errorBlockHandling = null

      // Remove events
      if (fieldType === 'input') {
        elem.removeEventListener('keyup', callFieldValidation)
      } else {
        elem.removeEventListener('change', callFieldValidation)
      }

      elem.removeEventListener('focus', showErrorBlock)
      elem.removeEventListener('blur', hideErrorBlock)

      if (inputBtn) {
        inputBtn.removeEventListener('focus', showErrorBlock)
      }

      // Remove Style
      resetInputState()
      if (markup && helper) {
        Utils.addClass(helper, 'cb-hidden')

        if (helper.querySelector('ul')) {
          helper.querySelector('ul').remove()
        } else {
          helper.innerHTML = ''
        }
      }
    }
  }

  if (elem.errorBlockHandling !== 'cb') {
    init()
  }

  return {
    destroy: destroy,
    validateAndReset: validateAndReset,
    resetValidation: resetValidation
  }
}


export default {
  fieldValidation,
  errorHandling,
  errorBlockHandling
}